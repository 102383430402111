import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  CardHeader,
  CardSubtitle,
  CardImg,
  CardTitle,
  Col,
  Row,
  Input
} from "reactstrap";
import { getDeliveryStatusColor } from "../../helpers/utils";
import moment from "moment";
import NumberFormat from "react-number-format";

const DeliveryOrderRowCard = ({ item, num, onRowCheck }) => {
  const {
    saleOrder,
    orderNo,
    type,
    quantity,
    deliveryId,
    isSelect,
  } = item;
  
  const { customerName, customerPhoneNumber, customerUniqueId, sellerName, saleOrderProducts } = saleOrder;
  const {  productImages } = saleOrderProducts[0];
  let productImage = productImages && productImages.length > 0 ? productImages[0] : null;

  return (
      <Card className={ `mb-2 border border-${ getDeliveryStatusColor(item) }` }>
        <CardHeader className={ "bg-transparent border-bottom p-0 d-flex" }>
          <div style={ { width: "150px", display: "flex", justifyContent: "center", alignItems: "center" } }>
            { productImage ? (
                <CardImg
                    style={ { width: "90px", height: "68px", objectFit: "cover" } }
                    src={ productImage.url }
                    alt={ productImage.fileName }
                />
            ) : (
                <div
                    style={ {
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    } }
                >
                  <div className="avatar-order">
                    { item.transportationType === "Sea" ? (
                        <i className="fas fa-solid fa-cube text-primary"></i>
                    ) : (
                        <i className="fas fa-solid fa-cube text-primary"></i>
                    ) }
                  </div>
                </div>
            ) }
          </div>

          <div className={ "flex-grow-1" }>
            <CardHeader>
              <Row style={ { cursor: "pointer" } }>
                <Col>
                  <CardTitle className={"mb-0"}>{type}</CardTitle>
                </Col>
                <Col md={ 1 }>
                  <Input
                      name={ `check_${ num }` }
                      onClick={ (e) => onRowCheck(e, item) }
                      checked={ isSelect }
                      type="checkbox"
                      readOnly
                  />
                </Col>
              </Row>
            </CardHeader>
            <div className={"py-2"}>
              <Row>
                <Col>
                  <div className={ 'flex-grow-1' }>
                    <CardTitle>Info</CardTitle>
                    <CardSubtitle className={ "font-size-13" }>
                      <span className={ "me-2 text-muted" }>Order No:</span>
                      <span className={ "ms-2" }>{ orderNo }</span>
                    </CardSubtitle>
                    <CardSubtitle className={ "font-size-13" }>
                      <span className={ "me-2 text-muted" }>Quantity:</span>
                      <span className={ "ms-2" }>{ quantity }</span>
                    </CardSubtitle>
                  </div>
                </Col>
                <Col>
                  <CardTitle>Customer Info</CardTitle>
                  <CardSubtitle className={ "font-size-13" }>
                    <span className={ "me-2 text-muted" }>Id:</span>
                    <span className={ "ms-2" }>{ customerUniqueId }</span>
                  </CardSubtitle>
                  <CardSubtitle className={ "font-size-13" }>
                    <span className={ "me-2 text-muted" }>Name:</span>
                    <span className={ "ms-2" }>{ customerName }</span>
                  </CardSubtitle>
                  <CardSubtitle className={ "font-size-13" }>
                    <span className={ "me-2 text-muted" }>Phone Number:</span>
                    <span className={ "ms-2" }>{ customerPhoneNumber }</span>
                  </CardSubtitle>
                </Col>
                <Col>
                  <CardTitle>Seller Info</CardTitle>
                  <CardSubtitle className={ "font-size-13" }>
                    <span className={ "me-2 text-muted" }>Name</span>
                    <span className={ "ms-2" }>{ sellerName }</span>
                  </CardSubtitle>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
      </Card>

  );
};

DeliveryOrderRowCard.propTypes = {
  item: PropTypes.shape({
    productImages: PropTypes.array,
    orderNo: PropTypes.string,
    type: PropTypes.string,
    deliveryId: PropTypes.string,
    isSelect: PropTypes.bool
  }).isRequired,
  num: PropTypes.number.isRequired,
  onRowCheck: PropTypes.func.isRequired
};

export default DeliveryOrderRowCard;
