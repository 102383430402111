import React, { useState, useEffect, useCallback } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  Input,
  FormGroup,
  CardHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import UserSelect from "containers/user/UserSelect";

import {
  getDeliveries,
  updateDelivery,
  deleteDelivery,
  clearDeliveryNotify,
} from "../../store/actions";

import DeliveryTable from "containers/delivery/DeliveryTable";

import {
  deliveryOrderStatusOptions,
  getCompanyName,
} from "helpers/utils";

import { debounce } from "lodash";
import moment from "moment";

const DeliveryList = props => {
  const {
    getDeliveries,
    deleteDelivery,
    history,
    delivery,
  } = props;
  const { loading, items, itemCount, pageCount} = delivery;

  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ selectedId, setSelectedId ] = useState(null);
  const [ term, setTerm ] = useState('');
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false);
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);
  const [ statuses, setStatuses ] = useState([]);
  const [ userId, setUserIds ] = useState(null)
  
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] = useState(false)
  
  const debouncedFetchData = useCallback(
    debounce(term => {
      setPage(1);
      setTerm(term);
    }, 500),
    []
  );

  useEffect(() => {
    getDeliveries({
      page,
      pageSize,
      term,
      startDate: startDate
          ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
      endDate: endDate
          ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
      statuses: statuses ? statuses.map(a => a.value) : [],
      userId: userId?.value,
    });
  }, [getDeliveries, page, pageSize, term, startDate, endDate, statuses, userId]);
  
  const handleOnEdit = id => {
    history.push(`/delivery/${id}`);
  };

  const handleConfirmDelete = id => {
    setSelectedId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleOnAddNew = () => {
    history.push(`/delivery/add-new`);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    debouncedFetchData(value);
  };
  
  const handleDelete = () => {
    deleteDelivery(selectedId);
    setModalConfirmDeleteIsOpen(false);
  };
  
  const handleExportToExcel = () => {
    // Will implement in futures
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delivery List | {getCompanyName()}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Delivery List" />
          <Row>
            <Col lg={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          selectsStart
                          name="startDate"
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Start Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          onChange={date => 
                            setEndDate(date)
                          }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="End Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <Select
                          name="statuses"
                          value={statuses}
                          onChange={option => setStatuses(option)}
                          options={deliveryOrderStatusOptions}
                          placeholder="Statuses"
                          className="mb-0"
                          classNamePrefix="select2-selection"
                          isMulti
                          allowSelectAll
                      />
                    </Col>
                    <Col md={3}>
                      <div className="text-sm-end me-3">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>

                        <ButtonDropdown
                          isOpen={moreActionIsOpen}
                          toggle={()=> setMoreActionIsOpen(!moreActionIsOpen)}
                        >
                          <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={handleExportToExcel}
                              className="text-primary"
                            >
                              <i className="fas fa-file-excel me-1" /> Export to Excel
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <UserSelect
                          id="driver-select"
                          name="userId"
                          value={userId}
                          roleNames={["System Administrator", "Administrator"]}
                          onChange={option => {
                            setUserIds(option);
                          }}
                          placeholder="Select Driver"
                          className="mb-0"
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="12">
                {items && (
                  <DeliveryTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={page}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={handleOnEdit}
                    onConfirmDelete={handleConfirmDelete}
                    onChangePage={page => setPage(page)}
                    onPageSizeChange={size => {
                      setPage(1);
                      setPageSize(size);
                    }}
                  />
                )}
               
                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={modalConfirmDeleteIsOpen}
                  toggle={()=>setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
                  onSubmit={handleDelete}
                />
              </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ delivery }) => {
  return {
    delivery,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeliveries,
    updateDelivery,
    deleteDelivery,
    clearDeliveryNotify,
  })(DeliveryList)
);
